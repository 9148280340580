table {
    width: 100%;
}

.table-text {
    word-wrap: break-word !important;
    white-space: unset !important;
    word-break: break-word;
    overflow-wrap: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

/*TRAINING TABLE*/

.mat-column-training {
    @extend .table-text;
    flex: 0 0 20% !important;
    padding-right: 16px;
}

.mat-column-date {
    @extend .table-text;
    flex: 0 0 22% !important;
    padding-right: 16px;
}

.mat-column-location {
    @extend .table-text;
    flex: 0 0 15% !important;
    padding-right: 16px;
}

.mat-column-spots {
    @extend .table-text;
    flex: 0 0 10% !important;
}

.mat-column-price {
    @extend .table-text;
    flex: 0 0 10% !important;
}

.mat-column-register-now {
    @extend .table-text;
    flex: 0 0 10% !important;
}

/*TEAM TABLE*/

.mat-column-firstName {
    @extend .table-text;
    flex: 0 0 15% !important;
}

.mat-column-insertion {
    @extend .table-text;
    flex: 0 0 13% !important;
}

.mat-column-lastName {
    @extend .table-text;
    flex: 0 0 15% !important;
}

.mat-column-email {
    @extend .table-text;
    flex: 0 0 30% !important;
}

.mat-column-typeUser {
    @extend .table-text;
    flex: 0 0 12% !important;
}

