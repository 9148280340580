.mat-mdc-dialog-container {
    .mat-mdc-dialog-surface {
        padding: 16px;
    }
    .mat-mdc-dialog-title {
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 500px) {
            width: 100%;
            display: block;
            margin-bottom: 20px;
            button {
                width: 100%;
            }
        }
    }
}
