$paddingMarginSizes: (
    xs: 4px,
    sm: 8px,
    md: 16px,
    lg: 24px,
    xl: 32px,
    xxl: 64px,
);

$locations: (
    top,
    left,
    right,
    bottom,
);

$properties: (
    padding,
    margin,
);

@each $paddingMarginSize, $size in $paddingMarginSizes {
    @each $location in $locations {
        @each $property in $properties {
            .#{$property}-#{$location}-#{$paddingMarginSize} {
                #{$property}-#{$location}: $size;
            }
        }
    }

    @each $property in $properties {

        .#{$property}-#{$paddingMarginSize} {
            #{$property}-top: $size;
            #{$property}-right: $size;
            #{$property}-bottom: $size;
            #{$property}-left: $size;
        }

        .#{$property}-horizontal-#{$paddingMarginSize} {
            #{$property}-left: $size;
            #{$property}-right: $size;
        }

        .#{$property}-vertical-#{$paddingMarginSize} {
            #{$property}-top: $size;
            #{$property}-bottom: $size;
        }
    }
}

.no-margin {
    margin: 0 !important;
}

.no-padding {
    padding: 0 !important;
}

.flex {
    display: flex;
}

.spacer {
    flex: 1;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.center-column {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.constraint {
    position: relative;
    max-width: 90rem;
    margin: 0 auto;
}

.content-padding {
    padding-left: 135px;
    padding-right: 135px;

    @media only screen and (max-width: 1024px) {
        padding-left: 16px;
        padding-right: 16px;
    }
}

.full-width {
    width: 100%;
}

.pancake-stack {
        display: grid;
        height: 100vh;
        grid-template-rows: auto 1fr auto;
}

