@use "sass:map";
@use "@angular/material" as mat;
@use "@angular/material/button/button-base";
@use "./ui/colors" as colour;

@font-face {
    font-family: 'GillSans';
    src: url('/assets/fonts/Gillsans/GillSans-SemiBold.otf') format('OpenType');
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GillSans';
    src: url('/assets/fonts/Gillsans/GillSans-Light.otf') format('OpenType');
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GillSans';
    src: url('/assets/fonts/Gillsans/GillSans-Regular.otf') format('OpenType');
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
}

@import "./ui/icons";


.app-styles.fr-box.fr-basic .fr-element.fr-view, .fr-view {


    $custom-typography-config: mat.define-typography-config(
        $font-family: '"GillSans", sans-serif',
        $headline-1: mat.define-typography-level(2.875rem, 1.22, 300),
        $headline-2: mat.define-typography-level(2.125rem, 1.18, normal),
        $headline-3: mat.define-typography-level(1.625rem, 1.23, normal),
        $headline-4: mat.define-typography-level(1.375rem, 1.27, normal),
        $headline-5: mat.define-typography-level(4.5rem, 1.11, 700),
        $headline-6: mat.define-typography-level(2.625rem, 1.33, 700),
        $subtitle-1: mat.define-typography-level(2.875rem, 1.22, 300),
        $body-1: mat.define-typography-level(1.125rem, 1.78, normal),
        $subtitle-2: mat.define-typography-level(1.125rem, 1.78, normal),
        $body-2: mat.define-typography-level(0.875rem, 2.29, normal),
        $caption: mat.define-typography-level(0.688rem, 1.85, 400),
        $button: mat.define-typography-level(0.875rem, normal, 600),
    );

    font-family: 'GillSans', sans-serif;
    color: black;

    h1 {
        font-size: 2.875rem !important;
        line-height: 1.22 !important;
        font-weight: 300 !important;
    }

    h2 {
        font-size: 2.125rem !important;
        line-height: 1.18 !important;
        font-weight: normal !important;
    }

    h3 {
        font-size: 1.625rem !important;
        line-height: 1.23 !important;
        font-weight: normal !important;
    }

    h4 {
        font-size: 1.375rem !important;
        line-height: 1.27 !important;
        font-weight: normal !important;
    }

    h5 {
        font-size: 1.375rem !important;
        line-height: 1.27 !important;
        font-weight: normal !important;
    }

    .text-raster {
        text-align: center;
        font-size: 18px !important;
        background-color: #d8d7d5;
        border-radius: 15px;
        padding: 15px;
        width: 100%;
    }

    p {
        font-size: 0.875rem;
        font-weight: normal;
        line-height: 2.29;

        > * {
            font-size: inherit;
            line-height: inherit;
        }
    }

    p > br {
        min-height: 32px;
    }

    .checkmark-ul {
        padding-left: 0;

        li {
            font-size: 1rem;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 4px;

            &:before {
                content: "\e904";
                font-family: icons;
                color: #db373d;
                margin-right: 11px;
            }
        }

        list-style: none !important;
    }

    .primary-button {
        @include mat.button-theme(colour.$website-theme);
        @include mat.fab-theme(colour.$website-theme);
        @include mat.icon-button-theme(colour.$website-theme);
        font-family: mat.font-family($custom-typography-config, button);
        font-size: mat.font-size($custom-typography-config, button);
        font-weight: mat.font-weight($custom-typography-config, button);
        background-image: linear-gradient(229deg, #db373d, #f53d44) !important;
        color: #ffffff !important;
        text-transform: uppercase;
        transition: .5s ease-in-out;


        &:hover {
            background-image: linear-gradient(229deg, #c25c60, #9b2126) !important;
            transition: .5s ease-in-out;
        }
    }

    .secondary-button {
        font-family: mat.font-family($custom-typography-config, button);
        font-size: mat.font-size($custom-typography-config, button);
        font-weight: mat.font-weight($custom-typography-config, button);
        background-image: linear-gradient(311deg, #1a3c66, #10253f) !important;
        color: #ffffff !important;
        text-transform: uppercase;
        transition: .5s ease-in-out;


        &:hover {
            background-image: linear-gradient(311deg, #486991, #253d59) !important;
            transition: .5s ease-in-out;
        }
    }
}

;
