.mat-tab-labels {
    padding-bottom: 20px;
}

.mdc-tab {
    max-height: 44px !important;
    display: flex;
    align-items: center;
    font-size: 0.875rem !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    border: 1px solid transparent !important;
    border-radius: 22px !important;
    background-color: var(--background-medium) !important;
    color: var(--dark) !important;
    font-weight: 600 !important;
    opacity: 1 !important;
    max-width: max-content;
    padding: 0 16px;

    &:not(:last-child) {
        margin-right: 10px !important;
    }

    .mdc-tab__text-label {
        font-weight: 600;
        color: var(--dark) !important;
    }

    .mdc-tab-indicator {
        display: none!important;
    }

    .mdc-tab__content {
        max-height: 44px !important;
        min-height: 44px !important;
    }
}

.mdc-tab--active {
    opacity: 1 !important;
    background-color: var(--primary) !important;

    .mdc-tab__text-label {
        color: var(--light) !important;
    }
}

.mat-ink-bar {
    background-color: transparent !important;
}
