
/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
mat-checkbox {
    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-label {
        font-size: 1rem;
        font-weight: normal;
        line-height: 2 !important;
        color: var(--dark);
        margin-top: 3px;
    }
     /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
     /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
     &.mat-checkbox-checked {
        /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        .mat-checkbox-label {
            font-size: 1rem;
            font-weight: 600 !important;
            line-height: 2 !important;
            color: var(--dark);
            margin-top: 2px;
        }
    }

    //&:hover {
    //    opacity: 0.4;
    //
    //    label {
    //        .mat-checkbox-label {
    //            font-size: 1rem;
    //            font-weight: 600;
    //            line-height: 2 !important;
    //            color: var(--dark);
    //            margin-top: 2px;
    //        }
    //
    //        .mat-checkbox-inner-container .mat-checkbox-background {
    //            background-color: var(--secondary);
    //
    //            svg {
    //                .mat-checkbox-checkmark-path {
    //                    stroke-dashoffset: 0;
    //                }
    //            }
    //        }
    //    }
    //}
}



