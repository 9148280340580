.mat-expansion-panel {
    background-color: var(--background-medium) !important;
    &-header {
        height: auto !important;
        &-title {
            font-size: 1rem;
        }
    }
    &-body {
    }
}
