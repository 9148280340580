@use '@angular/material' as mat;

$fontSizes: (
    xs: 0.875rem,
    sm: 1rem,
    md: 1.125rem,
    lg: 1.625rem,
    xl: 2.125rem,
    xxl: 2.875rem,
);

@each $fontSize, $size in $fontSizes {
    .font-size-#{$fontSize} {
        font-size: $size;
    }
}

@font-face {
    font-family: 'GillSans';
    src: url('/assets/fonts/Gillsans/GillSans-SemiBold.otf') format('OpenType');
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GillSans';
    src: url('/assets/fonts/Gillsans/GillSans-Light.otf') format('OpenType');
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GillSans';
    src: url('/assets/fonts/Gillsans/GillSans-Regular.otf') format('OpenType');
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
}

h1 {
    font-size: 2.875rem !important;
    line-height: 1.22 !important;
    font-weight: 300 !important;
}

h2 {
    font-size: 2.125rem !important;
    line-height: 1.18 !important;
    font-weight: normal !important;
}

h3 {
    font-size: 1.625rem !important;
    line-height: 1.23 !important;
    font-weight: normal !important;
}

h4 {
    font-size: 1.375rem !important;
    line-height: 1.27 !important;
    font-weight: normal !important;
}

h5 {
    font-size: 1rem !important;
    line-height: 1.78 !important;
    font-weight: normal !important;
}

h1, h2, h3, h4, h5, p, a, li, * {
    font-family: 'GillSans', sans-serif;
}

p {
    font-size: 0.875rem !important;
    font-weight: normal !important;
    line-height: 2.29 !important;

    > * {
        font-size: inherit;
        line-height: inherit;
    }
}

.p-large {
    font-size: 1.125rem !important;
    font-weight: normal !important;
    line-height: 1.78 !important;
}

.a {
    font-size: 1rem !important;
    font-weight: normal !important;
    line-height: 2 !important;
}

.a-bold {
    font-size: 0.875rem !important;
    font-weight: 600 !important;
    line-height: normal !important;
}

.label {
    font-size: 0.875rem !important;
    font-weight: 600 !important;
    line-height: 2.29 !important;
}

.label-small {
    font-size: 0.75rem !important;
    font-weight: 600 !important;
    line-height: 2.67 !important;
}

.button {
    font-size: 0.875rem !important;
    font-weight: 600 !important;
    line-height: normal !important;
}

.button-small {
    font-weight: normal !important;
}

.subheading {
    font-weight: 300 !important;
}

.font-weight-bold {
    font-weight: bold !important;
}

.font-weight-light {
    font-weight: lighter !important;
}

.center-text {
    text-align: center;
}

.primary {
    color: var(--primary);
}

.secondary {
    color: var(--secondary);
}

.dark {
    color: var(--dark);
}

.medium {
    color: var(--medium);
}

.medium-light {
    color: var(--medium-light);
}

.light {
    color: var(--light)
}

$custom-typography-config: mat.define-typography-config(
    $font-family: '"GillSans", sans-serif',
    $headline-1: mat.define-typography-level(2.875rem, 1.22, 300),
    $headline-2: mat.define-typography-level(2.125rem, 1.18, normal),
    $headline-3: mat.define-typography-level(1.625rem, 1.23, normal),
    $headline-4: mat.define-typography-level(1.375rem, 1.27, normal),
    $headline-5: mat.define-typography-level(4.5rem, 1.11, 700),
    $headline-6: mat.define-typography-level(2.625rem, 1.33, 700),
    $subtitle-1: mat.define-typography-level(2.875rem, 1.22, 300),
    $body-1: mat.define-typography-level(1.125rem, 1.78, normal),
    $subtitle-2: mat.define-typography-level(1.125rem, 1.78, normal),
    $body-2: mat.define-typography-level(0.875rem, 2.29, normal),
    $caption: mat.define-typography-level(0.688rem, 1.85, 400),
    $button: mat.define-typography-level(0.875rem, normal, 600),
);

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography-config);`
@include mat.all-component-typographies($custom-typography-config);
@include mat.core();
