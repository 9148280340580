.primary-gradient-button {
    background-image: var(--primary-gradient) !important;
    color: var(--light) !important;
    text-transform: uppercase;
    transition: .5s ease-in-out;

    &:hover {
        background-image: var(--primary-gradient-hover) !important;
        transition: .5s ease-in-out;
    }
}

.secondary-gradient-button {
    background-image: var(--secondary-gradient) !important;
    color: var(--light) !important;
    text-transform: uppercase;
    transition: .5s ease-in-out;

    &:hover {
        background-image: var(--secondary-gradient-hover) !important;
        transition: .5s ease-in-out;
    }
}

.stroked-icon-button {
    height: 44px;
    width: 44px;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.mat-mdc-menu-item-text {
    font-size: 14px !important;
}
