@font-face {
    font-family: 'icons';
    src: url('/assets/fonts/icons/icons.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    font-family: 'icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    color: currentColor;
}

.icon-calendar:before {
    content: "\e90a";
}

.icon-document:before {
    content: "\e90b";
}

.icon-training-calendar:before {
    content: "\e90c";
}

.icon-training-certificate:before {
    content: "\e90d";
}

.icon-download:before {
    content: "\e90e";
}

.icon-education:before {
    content: "\e90f";
}

.icon-info:before {
    content: "\e910";
}

.icon-price:before {
    content: "\e911";
}

.icon-repeat:before {
    content: "\e912";
}

.icon-arrow-down:before {
    content: "\e900";
}

.icon-arrow-right:before {
    content: "\e901";
}

.icon-arrow-left:before {
    content: "\e913";
}

.icon-caret-down:before {
    content: "\e902";
}

.icon-caret-right:before {
    content: "\e903";
}

.icon-checkmark:before {
    content: "\e904";
}

.icon-dropdown:before {
    content: "\e905";
}

.icon-mail:before {
    content: "\e906";
}

.icon-phone:before {
    content: "\e907";
}

.icon-play:before {
    content: "\e908";
}

.icon-search:before {
    content: "\e909";
}
