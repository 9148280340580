@import "theme/main";
@import "~cookieconsent/build/cookieconsent.min.css";
html, body {
    height: 100%;
    scroll-behavior: smooth;
}

body {
    margin: 0;
    background-color: var(--background-color);
}

*[data-f-id] {
    display: none;
}

.grecaptcha-badge {
    visibility: hidden;
}
