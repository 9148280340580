.mat-mdc-form-field-infix {
    font-size: 14px;
    display: flex;
    align-items: center;

    label {
        width: 100%;
    }
}

.mat-mdc-text-field-wrapper {
    background-color: white;
}

.mdc-floating-label {
    font-size: 14px !important;
}

mat-select {
    font-size: 14px !important;
}

mat-option {
    font-size: 14px !important;
}
